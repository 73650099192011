import React from 'react';

const About = () => {
  return (
    <div>
      <section class="">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light ">
            <h2 class="mb-4 text-4xl tracking-tight font-bold text-primary">
              About Dr. Prerana Rajput
            </h2>
            <p class="mb-4 text-lg">
            Dr. Prerana Rajput is a highly experienced and skilled eye surgeon in indore with over 9 years of experience in the field. She completed her M.B.B.S. from Gandhi Medical College, Bhopal followed by Post-graduation in Ophthalmology from Shyam Shah Medical College, Rewa. She went for further intensive training in Anterior Segment Surgeries at Sadguru Sankalp Netra Chikitsalaya (M.P.). She has also worked at ESIC & model hospital, indore for 3 years. She is an expert in advanced Cataract surgeries including Phacoemulsification with premium intraocular lens implantation. She is also an expert in Pterygium surgeries with autologous conjunctival graft, lacrimal sac surgeries (Dacryocystorhinostomy, Dacrocystectomy), eyelid surgeries (Ptosis, Entropion, Entropion, Dermoid cyst, traumatic lid-tear repair), Conjunctival tear repair, Corneal tear repair and other ocular surgeries. She also has the experience of botox treatment for blephrospasm and intravitreal anti-VEGF injections for diabetic retinopathy treatment.
            </p>
            <p>
              Join the ranks of satisfied patients who have entrusted us with
              their vision health.Schedule an appointment with us today, and let
              us guide you towards a future of clear vision and eye wellness.
              Experience the best in eye care at Dr. Prerana Rajput's Eye Care
              Centre, where your vision is our focus!
            </p>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg"
              src="https://lh3.googleusercontent.com/p/AF1QipN90Hnz9aKa9wQm-ysrloiOLmh5QGRbQRjfX0E2=s1360-w1360-h1020"
              alt="office content 1"
            />
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src="https://lh3.googleusercontent.com/p/AF1QipOByrYct68cveiZ64UU1h6Wn62bmjaQfuOEO-zt=s1360-w1360-h1020"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
