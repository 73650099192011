import React from 'react';

function Statistics() {
  return (
    <section className="statistics bg-secondary text-white py-4 md:py-8">
      {' '}
      {/* Adjust py-4 for smaller height */}
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="stat">
            <h3 className="text-4xl font-bold">300,000+</h3>
            <p>Happy Patients</p>
          </div>
          <div className="stat">
            <h3 className="text-4xl font-bold">30,000+</h3>
            <p>Completed Surgeries</p>
          </div>
          <div className="stat">
            <h3 className="text-4xl font-bold">6,000+</h3>
            <p>Eye Exams</p>
          </div>
          <div className="stat">
            <h3 className="text-4xl font-bold">14</h3>
            <p>Years of Experience</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statistics;
