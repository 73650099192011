import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import social media icons
import { Link } from 'react-router-dom';
import Logo from '../Images/12.png';
function Footer() {
  return (
    <footer className="bg-primary text-white p-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10">
        {/* Logo */}
        <div className="flex items-center justify-center md:col-span-1">
          <img src={Logo} alt="Logo" className="md:h-40 md:w-40  " />
          {/* Dr. Prerana Rajput's Eye Care Centre */}
        </div>

        {/* About */}
        <div className=" ">
          <h3 className="text-xl font-bold mb-2">About Us</h3>
          <p className="mb-4 text-justify">
            At Dr. Prerana Rajput's Eye Care Centre, we are dedicated to
            delivering exceptional eye care services tailored to meet the needs
            of our diverse patient base. With a commitment to excellence and
            compassion, our team of experienced ophthalmologists and
            optometrists offers comprehensive eye care solutions for patients of
            all ages.
          </p>
        </div>
        <div className="flex flex-col gap-5">
          {/* Address */}
          <p className="mb-2">
            <strong>Address:</strong> First Floor, The Landmark Building, Plot
            no.5, Scheme no. 53, MR 9 Rd, Barfani Dham, Indore, Madhya Pradesh
            452011
          </p>

          {/* Contact Info */}
          <p className="mb-2">
            <strong>Contact Us:</strong> 7723020626
          </p>
          <p>
            <strong>Email:</strong> prerana6575@gmail.com
          </p>
          {/* Social Media Icons */}
          <div className="flex space-x-4 mb-4">
            <Link
              to="https://www.facebook.com/drpreranarajputeyecarecentre"
              target="_blank"
              className="text-white hover:text-gray-300"
            >
              <FaFacebook className="text-2xl" />
            </Link>
            <Link to="#" className="text-white hover:text-gray-300">
              <FaTwitter className="text-2xl" />
            </Link>
            <Link to="#" className="text-white hover:text-gray-300">
              <FaInstagram className="text-2xl" />
            </Link>
          </div>
        </div>
      </div>{' '}
      {/* Links */}
      <div className="md:col-span-3 text-center flex justify-center md:text-right my-5">
        <Link to="/" className="text-white hover:text-gray-300 mx-2">
          Home
        </Link>
        <Link to="/cervices" className="text-white hover:text-gray-300 mx-2">
          Services
        </Link>
        <Link to="/gallery" className="text-white hover:text-gray-300 mx-2">
          Gallery
        </Link>
        <Link to="/contact" className="text-white hover:text-gray-300 mx-2">
          Contact
        </Link>
        <Link to="#" className="text-white hover:text-gray-300 mx-2">
          Privacy Policy
        </Link>
      </div>
      <span class="text-sm text-blue flex justify-center text-center ">
        © 2024{' '}
        <Link to="https://r4healthcare.in/" target="_blank">
          R4 Health Care™
        </Link>
        . All Rights Reserved.
      </span>
    </footer>
  );
}

export default Footer;
