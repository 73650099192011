import React from 'react';
import s4 from '../Images/Slider/s4.JPG';
const images = [
  [s4, s4, s4],
  [
    'https://img.freepik.com/free-photo/doctor-testing-patient-eyesight_23-2149230013.jpg?t=st=1718968997~exp=1718972597~hmac=1516a5613b823fed25c115c8c7ec73c6429de57cdae8f83f6c83e5e4c7a67daf&w=360',
    s4,
    'https://img.freepik.com/free-photo/horizontal-shot-interested-curious-caucasian-girl-appointment-with-eye-care-specialist-wearing-phoropter-while-ophthalmologist-checking-her-vision-sitting-yellow-wall_176420-6730.jpg?t=st=1718968746~exp=1718972346~hmac=a3ff8ab46ee20d59b0ff480a12a1db256c69e31551d57d56a2c80d80beba8b27&w=360',
  ],
  [
    'https://img.freepik.com/free-photo/horizontal-shot-interested-curious-caucasian-girl-appointment-with-eye-care-specialist-wearing-phoropter-while-ophthalmologist-checking-her-vision-sitting-yellow-wall_176420-6730.jpg?t=st=1718968746~exp=1718972346~hmac=a3ff8ab46ee20d59b0ff480a12a1db256c69e31551d57d56a2c80d80beba8b27&w=360',
    'https://img.freepik.com/free-photo/doctor-testing-patient-eyesight_23-2149230013.jpg?t=st=1718968997~exp=1718972597~hmac=1516a5613b823fed25c115c8c7ec73c6429de57cdae8f83f6c83e5e4c7a67daf&w=360',
    'https://img.freepik.com/free-photo/woman-eyes-consult_23-2148919986.jpg?t=st=1718969037~exp=1718972637~hmac=d79b79252fc4f678d2a050b1ed7477812578b3c8f378fc6d573d0b260dcbcc33&w=996',
  ],
  [
    s4,
    'https://img.freepik.com/free-photo/woman-eyes-consult_23-2148919986.jpg?t=st=1718969037~exp=1718972637~hmac=d79b79252fc4f678d2a050b1ed7477812578b3c8f378fc6d573d0b260dcbcc33&w=996',
    'https://img.freepik.com/free-photo/doctor-testing-patient-eyesight_23-2149230013.jpg?t=st=1718968997~exp=1718972597~hmac=1516a5613b823fed25c115c8c7ec73c6429de57cdae8f83f6c83e5e4c7a67daf&w=360',
  ],
];

const Gallery = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {images.map((column, colIndex) => (
          <div key={colIndex} className="grid gap-4">
            {column.map((src, imgIndex) => (
              <div key={imgIndex}>
                <img
                  className="h-auto max-w-full rounded-lg"
                  src={src}
                  alt={`Gallery image ${imgIndex + 1}`}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
