// Services.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const services = [
  {
    title: 'Cataract Treatment',
    description: 'Advanced cataract treatment with minimal recovery time.',
    image:
      'https://www.centreforsight.net/wp-content/uploads/2023/03/blog-wp-content-uploads-2020-04-a-complete-guide-on-laser-cataracts-surgery-meaning-benefits-and-treatment.webp',
  },
  {
    title: 'Lasik Surgery',
    description: 'State-of-the-art LASIK surgery for perfect vision.',
    image:
      'https://eye7.b-cdn.net/wp-content/uploads/woman-undergoing-vision-correction-surgery.jpg',
  },
  {
    title: 'ICL Surgery',
    description: 'Implantable Contact Lens surgery for clear vision.',
    image:
      'https://cdn.prod.website-files.com/5ff8126e9f80b718dc63f907/6526bce0624d37119e694345_visian-implantable-collamer-lens-icl-pros-and-cons.webp',
  },
  {
    title: 'Glaucoma Surgery',
    description: 'Effective glaucoma management and surgery.',
    image:
      'https://www.health.com/thmb/7JrjivLiY6AYzl_33qYKdNFrn5Y=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/Health-GettyImages-1557176331-fc33da30bc524411af59e3954ff8015f.jpg',
  },
  {
    title: 'Pediatric Eye Care',
    description: 'Comprehensive eye care services for children.',
    image:
      'https://www.mehtaeyeclinic.com/wp-content/uploads/2018/03/Pediatric-Ophthalmology.jpg',
  },
  {
    title: 'Retina Treatment',
    description: 'Specialized care for retinal disorders.',
    image:
      'https://newvisioneyecenter.ae/wp-content/uploads/2021/12/retina-1.jpg',
  },
];

const Services = () => {
  return (
    <section className="services py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-primary mb-8">
          Special High-quality Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-5">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: index * 0.2 }}
              className="service-card bg-gray-100 p-2 rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-2 transition-all duration-300"
            >
              <Link to="/services">
                <img
                  src={service.image}
                  alt={service.title}
                  className="h-48 w-full object-cover rounded-md mb-4"
                />
                <h3 className="text-xl font-semibold text-secondary mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-700">{service.description}</p>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
