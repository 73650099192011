import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const blogs = [
  {
    id: 1,
    title: 'The Importance of Regular Eye Check-ups',
    excerpt:
      'Discover why regular eye check-ups are essential for maintaining good eye health...',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsq7p5RVhk0Veso_ue7qtn7fDbvMLfvlTZPQ&s',
    url: '#',
  },
  {
    id: 2,
    title: 'Top 10 Tips for Healthy Eyes',
    excerpt:
      'Learn the top 10 tips to keep your eyes healthy and improve your vision...',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0blaDdiYo_4sm6VwuGPH5et6zSiEuM-YyoQ&s',
    url: '#',
  },
  {
    id: 3,
    title: 'Understanding Cataract Surgery',
    excerpt:
      'Everything you need to know about cataract surgery and how it can restore your vision...',
    image: 'https://www.relainstitute.com/wp-content/uploads/2022/11/687.jpg',
    url: '#',
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const LatestBlogs = () => {
  return (
    <section className="latest-blogs px-10 py-10">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-primary mb-8">
          Latest From Our Blogs
        </h2>
        <Slider {...settings}>
          {blogs.map((blog) => (
            <motion.div key={blog.id} className="px-2">
              <motion.a
                href={blog.url}
                target="_blank"
                rel="noopener noreferrer"
                className="relative group block rounded-lg overflow-hidden shadow-lg transition-transform duration-300"
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-48 object-cover transition-transform duration-500 ease-in-out transform group-hover:scale-110"
                />
                <div className="p-4 bg-white">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    {blog.title}
                  </h3>
                  <p className="text-gray-600">{blog.excerpt}</p>
                </div>
                <motion.div
                  className="absolute inset-0 bg-primary bg-opacity-0 group-hover:bg-opacity-70 flex items-center justify-center transition-all duration-500"
                  initial={{ opacity: 0 }}
                  whileHover={{ opacity: 1 }}
                >
                  <h3 className="text-white text-lg font-semibold">
                    {blog.title}
                  </h3>
                </motion.div>
              </motion.a>
            </motion.div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default LatestBlogs;
