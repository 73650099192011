import React from 'react';
import { motion } from 'framer-motion';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const Contact = () => {
  const position = [22.7196, 75.8577]; // Coordinates for the location

  return (
    <section className="contact py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold text-primary mb-8">
          Need Help? Get in Touch.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="contact-info bg-white p-6 rounded-lg shadow-md col-span-1 lg:col-span-1"
          >
            <h3 className="text-2xl text-start font-semibold text-secondary mb-4">
              Contact Details
            </h3>
            <p className="text-lg mb-2 flex items-center text-justify">
              {/* <FaMapMarkerAlt className="mr-2 text-primary" /> */}
              {/* <strong>Address:</strong> */}
              First Floor, The Landmark Building, Plot no.5, Scheme no. 53, MR 9
              Rd, Barfani Dham, Indore, Madhya Pradesh 452011
            </p>
            <p className="text-lg mb-2 flex items-center">
              {/* <FaPhoneAlt className="mr-2 text-primary" />
              <strong>Phone:</strong>  */}
              7723020626
            </p>
            <p className="text-lg mb-2 flex items-center">
              {/* <FaEnvelope className="mr-2 text-primary" />
              <strong>Email:</strong>  */}
              prerana6575@gmail.com
            </p>
            <div className="contact-image my-10">
              <img
                src="https://img.freepik.com/free-photo/portrait-woman-customer-service-worker_144627-37948.jpg?t=st=1718883356~exp=1718886956~hmac=34c8293308d2d7b85d7fbe4dfeef5692c8dc45b416c117ccf6ab7b5d0b93f4be&w=900"
                alt="Contact Us"
                className="rounded-lg shadow-lg w-full object-cover"
              />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="map-container bg-white p-6 rounded-lg shadow-md col-span-1 lg:col-span-2"
          >
            <div className="mt-8">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="contact-form bg-white p-6 rounded-lg shadow-md"
              >
                <form className="space-y-4">
                  <div>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="Your Name"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="Your Email"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="Subject"
                    />
                  </div>
                  <div>
                    <textarea
                      rows="4"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </motion.div>
            </div>

            <MapContainer
              center={position}
              zoom={13}
              className="h-64 w-full rounded-lg z-0"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14718.540613369736!2d75.8982694!3d22.7417984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd7f27c8b6f5%3A0x3f25dcc3cd834f1b!2sDr.%20Prerana%20Rajput&#39;s%20Eye%20Care%20Centre-%20Ophthalmologist%7CEye%20Surgeon%7CCataract%20Surgeon%7CPhaco%20Surgeon%7CEye%20Specialist%7CIndore!5e0!3m2!1sen!2sin!4v1722939500766!5m2!1sen!2sin"
                width="100%"
                height="600"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <Marker position={position}>
                <Popup>Dr. Pranay Singh's Eye Care Centre</Popup>
              </Marker>
            </MapContainer>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
