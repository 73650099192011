import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import s1 from '../Images/Slider/s1.png';
import s2 from '../Images/Slider/s2.png';
import s3 from '../Images/Slider/s3.png';
import s4 from '../Images/Slider/s4.JPG';

function Slider() {
  return (
    <div className="slider">
      <Carousel autoPlay infiniteLoop showThumbs={false}>
        <div>
          <img src={s1} alt="Slide 1" className="carousel-image" />
          <p className="legend">Cataract Surgery</p>
        </div>
        <div>
          <img src={s2} alt="Slide 2" className="carousel-image" />
          <p className="legend">Lasik Surgery</p>
        </div>
        <div>
          <img src={s3} alt="Slide 3" className="carousel-image" />
          <p className="legend">Cataract Treatment</p>
        </div>
        <div>
          <img src={s4} alt="Slide 4" className="carousel-image" />
          <p className="legend">ICL Surgery</p>
        </div>
        {/* Add more slides as needed */}
      </Carousel>
    </div>
  );
}

export default Slider;
