import React from 'react';
import { motion } from 'framer-motion';
import s4 from '../Images/Slider/s4.JPG';
function About() {
  return (
    <section className="about  py-16">
      <div className="container mx-auto gap-5 flex flex-col md:flex-row items-center">
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="md:w-1/2 mt-8 md:mt-0 md:ml-8 text-center md:text-left"
        >
          <h2 className="text-3xl font-bold text-primary mb-4">
            Dr. Prerana Rajput
          </h2>
          <p className="text-lg">
          Dr. Prerana Rajput is a highly experienced and skilled eye surgeon in indore with over 9 years of experience in the field. She completed her M.B.B.S. from Gandhi Medical College, Bhopal followed by Post-graduation in Ophthalmology from Shyam Shah Medical College, Rewa. She went for further intensive training in Anterior Segment Surgeries at Sadguru Sankalp Netra Chikitsalaya (M.P.). She has also worked at ESIC & model hospital, indore for 3 years. She is an expert in advanced Cataract surgeries including Phacoemulsification with premium intraocular lens implantation. She is also an expert in Pterygium surgeries with autologous conjunctival graft, lacrimal sac surgeries (Dacryocystorhinostomy, Dacrocystectomy), eyelid surgeries (Ptosis, Entropion, Entropion, Dermoid cyst, traumatic lid-tear repair), Conjunctival tear repair, Corneal tear repair and other ocular surgeries. She also has the experience of botox treatment for blephrospasm and intravitreal anti-VEGF injections for diabetic retinopathy treatment.<br />
            Experience the best in eye care at Dr. Prerana Rajput's Eye Care
            Centre, where your vision is our focus!
          </p>
        </motion.div>{' '}
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="md:w-1/2"
        >
          <img
            src={s4}
            alt="Dr. Pranay Singh"
            className="rounded-lg shadow-lg object-cover md:h-[500px] h-full md:w-[924px] w-full"
          />
        </motion.div>
      </div>
    </section>
  );
}

export default About;
