import React from 'react';
import Slider from '../components/Slider';
import About from '../components/About';
import Services from '../components/Services';
import Statistics from '../components/Statistics';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';

import Blog from '../components/Blog';
import LatestVideos from '../components/Videos';
import LatestBlogs from '../components/Blog';

function Home() {
  return (
    <div>
      <Slider />
      <About />
      <Services />
      {/* <Statistics /> */}
      {/* <Testimonials /> */}
      {/* <LatestVideos /> */}
      <LatestBlogs /> <Contact />
    </div>
  );
}

export default Home;
