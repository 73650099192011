// ServiceDetail.jsx
import React from 'react';

const services = [
  {
    title: 'Cataract Surgery',
    description:
      'Our cataract surgery is a quick and efficient outpatient procedure that typically takes only 5-7 minutes. Using the latest techniques, we ensure minimal recovery time and outstanding visual results. Our skilled surgeons remove the clouded lens and replace it with a clear, artificial intraocular lens (IOL), restoring your vision effectively.',
    linkText: 'Click here',
    image:
      'https://www.dragarwal.com/wp-content/uploads/2024/02/Cataract-Surgery-Dr-Agarwals-Eye-Hospital-scaled.jpg',
  },
  {
    title: 'Glaucoma Management',
    description:
      'We offer advanced glaucoma management services using the latest techniques and state-of-the-art equipment. Our comprehensive approach includes early detection, monitoring, and various treatment options such as medications, laser therapy, and surgical interventions to control intraocular pressure and prevent vision loss.',
    linkText: 'Click here',
    image:
      'https://www.health.com/thmb/7JrjivLiY6AYzl_33qYKdNFrn5Y=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc()/Health-GettyImages-1557176331-fc33da30bc524411af59e3954ff8015f.jpg',
  },
  {
    title: 'Oculoplasty',
    description:
      'Enhance the appearance and function of your eyes with our specialized oculoplasty services. We provide cosmetic and reconstructive procedures to address issues such as droopy eyelids, tear duct obstructions, and orbital diseases. Our expert surgeons use precise techniques to improve both aesthetics and ocular health.',
    linkText: 'Click here',
    image:
      'https://www.slma.cc/wp-content/uploads/2017/07/shutterstock_467892755-1080x675.jpg',
  },
  {
    title: 'Pediatric Eye Care',
    description:
      'Our pediatric eye care services cater to all possible eye treatment solutions for children. From routine eye exams and vision screenings to treating conditions like strabismus (crossed eyes) and amblyopia (lazy eye), we ensure your childs eyes are healthy and developing properly. Our child-friendly approach makes the experience comfortable and stress-free.',
    linkText: 'Click here',
    image:
      'https://www.slma.cc/wp-content/uploads/2017/07/shutterstock_467892755-1080x675.jpg',
  },
  {
    title: 'Squint Correction',
    description:
      'We offer both surgical and non-surgical options for squint correction. Our experienced team evaluates the best treatment plan for each patient, whether it involves exercises, glasses, or surgical intervention. We aim to improve eye alignment and vision while enhancing overall quality of life.',
    linkText: 'Click here',
    image:
      'https://www.slma.cc/wp-content/uploads/2017/07/shutterstock_467892755-1080x675.jpg',
  },
  {
    title: 'Refraction',
    description:
      'Regular check-ups and the appropriate use of glasses can correct refraction errors such as myopia (nearsightedness), hyperopia (farsightedness), and astigmatism. Our comprehensive refraction services ensure you receive the correct prescription for clear and comfortable vision.',
    linkText: 'Click here',
    image:
      'https://www.slma.cc/wp-content/uploads/2017/07/shutterstock_467892755-1080x675.jpg',
  },
  {
    title: 'Contact Lens Clinic',
    description:
      'Find the perfect contact lenses for your preference and lifestyle at our contact lens clinic. We offer a wide range of options, including daily disposables, extended wear lenses, and specialized lenses for astigmatism and presbyopia. Our experts provide fitting and care instructions to ensure optimal comfort and vision.',
    linkText: 'Click here',
    image: 'https://thesightavenue.com/public/frontend/assets/images/ICL_1.jpg',
  },
  {
    title: 'Laser Surgery',
    description:
      'Achieve 20/20 vision with our laser surgery services, which reshape the cornea to correct refractive errors like myopia, hyperopia, and astigmatism. Using advanced technology such as LASIK and PRK, we provide precise and effective vision correction with quick recovery times and minimal discomfort.',
    linkText: 'Click here',
    image:
      'https://www.akhandjyoti.com/storage/blog/detail/blog_25626d98f6caea0fb47691ef8b979938.webp',
  },
  {
    title: 'Retinal Management',
    description:
      'Our retinal management services offer the very best in retina and uvea care. We diagnose and treat conditions such as diabetic retinopathy, macular degeneration, and retinal detachment using advanced diagnostic tools and treatment options. Our goal is to preserve and improve your retinal health and vision.',
    linkText: 'Click here',
    image:
      'https://dmgwebprodstorage.blob.core.windows.net/dmgprodweb/hero-images/retinal-conditions-treatment-options.jpg',
  },
];

const ServiceDetail = () => {
  return (
    <section className="service-detail py-16">
      <div className="container mx-auto text-center">
        {services.map((service, index) => (
          <div key={index} className="shado p-10 rounded-2xl my-10 m-10">
            <h2 className="text-3xl font-bold text-primary mb-8">
              {service.title}
            </h2>
            <div className="grid md:grid-cols-2 justify-center items-center  grid-col-1 gap-10">
              <img
                src={service.image}
                alt={service.title}
                className=" w-full object-cover rounded-md mb-4"
              />
              <p className="text-gray-700 mb-8  text-justify">
                {service.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceDetail;
