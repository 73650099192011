import React, { useState } from 'react';
import { FaFacebook, FaGoogle, FaInstagram } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import logo from '../Images/12.png';
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to set active link
  const handleSetActiveLink = (link) => {
    setActiveLink(link);
    setIsOpen(false); // Close mobile menu on link click
  };

  return (
    <>
      <nav className="fixed top-0 w-full bg-primary p-4 z-10">
        <div className="container mx-auto flex justify-between items-center">
          <NavLink
            exact
            to="/"
            activeClassName="font-bold bg-secondary underline"
            className="text-white font-bold"
          >
            <img src={logo} alt="" className="w-10 h-10 " />
            {/* Dr. Prerana Rajput's Eye Care Centre */}
          </NavLink>
          <div className="hidden md:flex space-x-4">
            <NavLink
              exact
              to="/"
              className={`px-4 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/')}
            >
              Home
            </NavLink>
            <NavLink
              to="/services"
              className={`px-4 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/services' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/services')}
            >
              Services
            </NavLink>
            <NavLink
              to="/about"
              className={`px-4 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/about' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/about')}
            >
              About Us
            </NavLink>
            <NavLink
              to="/gallery"
              className={`px-4 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/gallery' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/gallery')}
            >
              Gallery
            </NavLink>
            <NavLink
              to="/contact"
              className={`px-4 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/contact' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/contact')}
            >
              Contact
            </NavLink>
          </div>
          <div className="hidden md:flex space-x-4">
            <NavLink
              to="https://www.facebook.com/drpreranarajputeyecarecentre"
              target="_blank"
              className="text-white hover:bg-gray-800 rounded-full p-2"
            >
              <FaFacebook size={24} />
            </NavLink>
            <NavLink
              to="#"
              className="text-white hover:bg-gray-800 rounded-full p-2"
            >
              <FaInstagram size={24} />
            </NavLink>
            <NavLink
              target="_blank"
              to="https://www.google.com/search?q=prenna+rajput+docter+in+indore&oq=prenna+rajput+docter+in+&gs_lcrp=EgZjaHJvbWUqCQgBECEYChigATIGCAAQRRg5MgkIARAhGAoYoAEyCQgCECEYChigATIJCAMQIRgKGKABMgcIBBAhGJIDMgcIBRAhGJIDMgcIBhAhGJIDMgcIBxAhGJIDMgcICBAhGJID0gEJMTI1MDFqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8"
              className="text-white hover:bg-gray-800 rounded-full p-2"
            >
              <FaGoogle size={24} />
            </NavLink>
          </div>
          <button className="md:hidden text-white" onClick={toggleMenu}>
            {isOpen ? '✕' : '☰'}
          </button>
        </div>
        {isOpen && (
          <div className="md:hidden bg-secondary text-white p-4">
            <NavLink
              exact
              to="/"
              className={`block px-4 py-2 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/')}
            >
              Home
            </NavLink>
            <NavLink
              exact
              to="/about"
              className={`block px-4 py-2 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/about' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/about')}
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              className={`block px-4 py-2 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/services' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/services')}
            >
              Services
            </NavLink>
            <NavLink
              to="/gallery"
              className={`block px-4 py-2 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/gallery' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/gallery')}
            >
              Gallery
            </NavLink>
            <NavLink
              to="/contact"
              className={`block px-4 py-2 text-white hover:bg-secondary rounded-md p-1 ${
                activeLink === '/contact' && 'bg-secondary rounded-md'
              }`}
              onClick={() => handleSetActiveLink('/contact')}
            >
              Contact
            </NavLink>
          </div>
        )}
      </nav>
      {/* Placeholder div to ensure content starts below the fixed navbar */}
      <div className="mt-16">
        {/* Replace mt-16 with appropriate margin based on your design */}
      </div>
    </>
  );
}

export default Navbar;
